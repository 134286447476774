'use strict';

$(document).ready(function() {
  // mobile menu

  // animations
  let open = anime({
    targets: '.navigation',
    translateX: -1100,
    duration: 1000,
    autoplay: false
  });

  let about = anime({
    targets: '.list-navigation li',
    translateX: [{
      value: (el, i) => (i + 1) * 50,
      duration: 150,
      easing: 'easeInOutQuart'
    }, {
      value: 0,
      duration: 600,
      easing: 'easeInOutQuart'
    }],
    delay: (el, i) => i * 80
  });

  let close = anime({
    targets: '.navigation',
    translateX: [{
      value: -1100,
      duration: 1
    }, {
      value: 0,
      duration: 200,
      easing: 'linear'
    }, {
      value: 1800,
      duration: 1
    }],
    autoplay: false
  });

  $(".js-mobileNavigation").on('click', function(e) {
    e.preventDefault();
    if ($(this).hasClass('is-active')) {
      close.play();
    } else {
      open.play();
      about.play();
    }
    $(this).toggleClass('is-active');
  });

  // animation scroll reveal

  var x = $('.js-reveal');
  //console.log(x);
  ScrollReveal().reveal(x, {
     interval: 300,
     reset: false,
     distance: '400px',
     duration: 500
   });
  ScrollReveal().reveal('.js-reveal-left', {
    origin: 'left'
  });
  ScrollReveal().reveal('.js-reveal-right', {
    origin: 'right'
  });
  ScrollReveal().reveal('.js-reveal-fast', {
    interval: 150,
    duration: 300
  });
});

$(document).ready(function() {
  $(".sliding-link").click(function(e) {
    e.preventDefault();
    var aid = $(this).attr("href");
    $('html,body').animate({scrollTop: $(aid).offset().top - 50},'slow');
  });
});

$(document).ready(function() {
  $(".unhide").click(function(e){
    e.preventDefault();
    $(".first-hidden").show(1000);
    $(".unhide").hide();
  });
});

